import React from "react";

import {
  TextInput,
  TelInput,
  EmailInput,
  DateInput,
  SelectInput,
  RadioInput,
  CheckboxInput,
  TextareaInput,
} from "../components/FormFields";

const capitalize = expression =>
  expression.charAt(0).toUpperCase() + expression.slice(1);

const Components = {
  TextInput,
  TelInput,
  EmailInput,
  DateInput,
  SelectInput,
  RadioInput,
  CheckboxInput,
  TextareaInput,
};

export const renderInput = (input, setInputs, modifyInputs, calc) => {
  const Component = Components[capitalize(input.type) + "Input"];

  return (
    <Component
      key={input.name}
      setInputs={setInputs}
      modifyInputs={modifyInputs}
      {...input}
      productId={calc}
    />
  );
};
