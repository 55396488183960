import React, { useRef } from "react";
import { Col } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { isIE, isIOS, isAndroid } from "react-device-detect";
import styled, { css } from "styled-components";

import { ValidationAlert } from "./ValidationAlert";

import icoArrow from "../../images/input-select-arrow.svg";

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export const TextareaInput = ({
  name,
  label,
  value,
  placeholder,
  alert,
  hiddenAlert,
  setInputs,
  col,
  productId,
}) => {
  return (
    <Col md={col} className="form-group" id={name + "-form-group"}>
      <div className="input textarea">
        {label && <StyledLabel htmlFor={name}>{label}</StyledLabel>}
        <textarea
          id={name}
          name={name}
          placeholder={placeholder || ""}
          value={value || ""}
          onChange={setInputs}
          className={alert ? "error" : ""}
        />
        <ValidationAlert content={alert} />
      </div>
    </Col>
  );
};

export const TextInput = ({
  name,
  label,
  type,
  value,
  placeholder,
  inputMode,
  readonly,
  alert,
  hiddenAlert,
  setInputs,
  modifyInputs,
  col,
  productId,
  masked,
}) => {
  const inputElement = useRef(null);

  const setValue = () => {
    if (name === "income" || name === "amount") {
      if (inputElement.current.value === "$ 0 MXN") {
        inputElement.current.value = "$  MXN";
      }
    }
  };

  return (
    <Col md={col} className="form-group" id={name + "-form-group"}>
      {label && <StyledLabel htmlFor={name}>{label}</StyledLabel>}

      {/* TODO: разделить на два компонента */}
      {masked ? (
        <ConditionalWrapper
          condition={name === "income"}
          wrapper={children => (
            <div className={"form-control-income-wrapper"}>{children}</div>
          )}
        >
          <NumberFormat
            id={name}
            name={name}
            type={type}
            value={value || ""}
            placeholder={placeholder || ""}
            inputMode={inputMode || "text"}
            readOnly={readonly || false}
            className={alert ? "form-control error" : "form-control"}
            onFocus={setValue}
            onChange={setInputs}
            onBlur={modifyInputs}
            isNumericString={masked.isNumericString || false}
            thousandSeparator={masked.thousandSeparator || null}
            decimalSeparator={masked.decimalSeparator || "."} // "." is default value from NumberFormat docs
            allowEmptyFormatting={masked.allowEmptyFormatting || false}
            prefix={masked.prefix || null}
            suffix={masked.suffix || null}
            format={masked.format || null}
            mask={masked.mask || null}
            displayType={"input"}
            getInputRef={inputElement}
          />
        </ConditionalWrapper>
      ) : (
        <input
          id={name}
          name={name}
          type={type}
          value={value || ""}
          placeholder={placeholder || ""}
          inputMode={inputMode || "text"}
          readOnly={readonly || false}
          className={alert ? "form-control error" : "form-control"}
          onChange={setInputs}
          onBlur={modifyInputs}
        />
      )}
      <ValidationAlert content={alert} />
    </Col>
  );
};

export const TelInput = ({
  name,
  label,
  type,
  value,
  placeholder,
  inputMode,
  readonly,
  alert,
  hiddenAlert,
  setInputs,
  modifyInputs,
  col,
  productId,
  masked,
}) => {
  return (
    <Col md={col} className="form-group" id={name + "-form-group"}>
      {label && <StyledLabel htmlFor={name}>{label}</StyledLabel>}
      <NumberFormat
        id={name}
        name={name}
        type={type}
        value={value || ""}
        placeholder={placeholder || ""}
        inputMode={inputMode || "text"}
        readOnly={readonly || false}
        className={alert ? "form-control error" : "form-control"}
        onChange={setInputs}
        onBlur={modifyInputs}
        isNumericString={masked.isNumericString || false}
        thousandSeparator={masked.thousandSeparator || null}
        decimalSeparator={masked.decimalSeparator || "."} // "." is default value from NumberFormat docs
        allowEmptyFormatting={masked.allowEmptyFormatting || false}
        prefix={masked.prefix || null}
        suffix={masked.suffix || null}
        format={masked.format || null}
        mask={masked.mask || null}
        displayType={"input"}
      />
      <ValidationAlert content={alert} />
    </Col>
  );
};

export const EmailInput = ({
  name,
  label,
  type,
  value,
  placeholder,
  alert,
  hiddenAlert,
  setInputs,
  col,
  productId,
  maxLength,
}) => {
  return (
    <Col md={col} className="form-group" id={name + "-form-group"}>
      {label && <StyledLabel htmlFor={name}>{label}</StyledLabel>}
      <input
        id={name}
        name={name}
        type={type}
        value={value || ""}
        placeholder={placeholder || ""}
        className={alert ? "form-control error" : "form-control"}
        onChange={setInputs}
      />
      <ValidationAlert content={alert} />
    </Col>
  );
};

export const DateInput = ({
  name,
  label,
  type,
  value,
  placeholder,
  alert,
  hiddenAlert,
  setInputs,
  col,
  productId,
}) => {
  return isIE ? (
    <StyledDateInput md={col} className="form-group" id={name + "-form-group"}>
      {label && <StyledLabel htmlFor={name}>{label}</StyledLabel>}
      <NumberFormat
        id={name}
        name={name}
        type="text"
        value={value || ""}
        placeholder="mm / dd / yyyy"
        inputMode="numeric"
        className={alert ? "form-control error" : "form-control"}
        onChange={setInputs}
        allowEmptyFormatting={true}
        format="## / ## / ####"
        mask={["m", "m", "d", "d", "y", "y", "y", "y"]}
        displayType={"input"}
      />
      <ValidationAlert content={alert} />
    </StyledDateInput>
  ) : (
    <StyledDateInput md={col} className="form-group" id={name + "-form-group"}>
      {label && <StyledLabel htmlFor={name}>{label}</StyledLabel>}
      <input
        id={name}
        name={name}
        type={type}
        value={value || ""}
        placeholder={placeholder || ""}
        className={alert ? "form-control error" : "form-control"}
        onChange={setInputs}
      />
      <ValidationAlert content={alert} />
    </StyledDateInput>
  );
};

export const CheckboxInput = ({
  name,
  label,
  type,
  value,
  alert,
  hiddenAlert,
  setInputs,
  col,
  productId,
}) => {
  return (
    <Col md={col} className="form-group" id={name + "-form-group"}>
      <div className="custom-control custom-checkbox">
        <input
          id={name}
          name={name}
          type={type}
          value={value}
          checked={value || false}
          className={
            alert ? "custom-control-input error" : "custom-control-input"
          }
          onChange={setInputs}
        />
        <label
          htmlFor={name}
          className={"custom-control-label"}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      </div>
      <ValidationAlert content={alert} />
    </Col>
  );
};

export const SelectInput = ({
  name,
  label,
  value,
  options,
  alert,
  hiddenAlert,
  setInputs,
  col,
  productId,
}) => {
  return (
    <StyledSelectInput
      md={col}
      className="form-group"
      id={name + "-form-group"}
    >
      {label && <StyledLabel htmlFor={name}>{label}</StyledLabel>}
      <select
        id={name}
        name={name}
        value={value}
        className={alert ? "form-control error" : "form-control"}
        onChange={setInputs}
      >
        {options &&
          options.map(option => (
            <option
              key={option.value}
              value={option.value}
              selected={option.selected || false}
              disabled={option.disabled || false}
            >
              {option.name}
            </option>
          ))}
      </select>
      <ValidationAlert content={alert} />
    </StyledSelectInput>
  );
};

export const RadioInput = ({
  name,
  label,
  type,
  options,
  alert,
  hiddenAlert,
  setInputs,
  col,
  productId,
}) => {
  return (
    <StyledRadioInput md={col} className="form-group" id={name + "-form-group"}>
      {label && <StyledFakeLabel>{label}</StyledFakeLabel>}
      <div className={"form-check-wrapper" + (alert ? " error" : "")}>
        {options &&
          options.map(option => (
            <div key={option.value} className="form-check form-check-inline">
              <input
                id={name + "_" + option.name}
                name={name}
                type={type}
                value={option.value}
                className="form-check-input"
                onChange={setInputs}
              />
              <label
                htmlFor={name + "_" + option.name}
                className={"form-check-label"}
                dangerouslySetInnerHTML={{ __html: option.name }}
              />
            </div>
          ))}
      </div>
      <ValidationAlert content={alert} />
    </StyledRadioInput>
  );
};

const stylesLabel = css`
  color: #4c4c4c;
  font-size: 16px;
  @media (min-width: 992px) {
    margin: 0 0 15px;
    font-size: 18px;
  }
`;

const StyledLabel = styled.label`
  ${stylesLabel};
`;

const StyledFakeLabel = styled.p`
  ${stylesLabel};
  @media (min-width: 992px) {
    margin: 0 0 25px;
  }
`;

const StyledDateInput = styled(Col)`
  .form-control {
    height: 44px; // Fix collapsed height on iOS, if field is empty
  }
`;

const StyledSelectInput = styled(Col)`
  select {
    width: 100%;
    height: auto;
    background: url(${icoArrow}) no-repeat center right 20px/16px 16px;
    padding: 9px 16px;
    border: 1px solid #378aff;
    border-radius: 4px;
    color: #378aff;
    font-size: 16px;
    line-height: 24px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    @media (min-width: 992px) {
      font-size: 18px;
    }
    &:focus {
      color: #378aff;
      border-color: #378aff;
      box-shadow: none;
      outline: none;
      text-shadow: none;
    }
    &.error {
      background-color: #ff8c8e;
      border: solid 1px #ff0505;
    }
  }
`;

const StyledRadioInput = styled(Col)`
  .form-check-wrapper + .error {
    font-size: 12px;
    color: red;
    @media (min-width: 768px) {
      font-size: 14px;
      position: absolute;
    }
  }
  .form-check-inline {
    min-width: 140px;
    .form-check-input {
      position: absolute;
      visibility: hidden;
    }
    .form-check-label {
      ${stylesLabel};
      position: relative;
      padding: 0 0 0 30px;
      margin: 0 0 0 -7px;
      @media (min-width: 992px) {
        margin: 0 0 0 -7px;
      }
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 7px;
        width: 16px;
        height: 16px;
        border: 1px solid hsl(215, 100%, 69%);
        border-radius: 50%;
        transform: translateY(-8px);
      }
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 10px;
        width: 10px;
        height: 10px;
        background: #5fa2ff;
        border-radius: 50%;
        box-shadow: 0 0 2px hsla(215, 100%, 69%, 0.5);
        opacity: 0;
        transform: translateY(-5px) scale(0);
        transition: opacity, transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
      }
    }
    .form-check-input:checked + .form-check-label::after {
      opacity: 1;
      transform: translateY(-5px);
    }
  }
`;
