import { useState, useEffect } from "react";

import { FORM } from "../settings";
import { registrationFirstStepModel } from "../models/registrationFirstStepModel";
import { registrationStepTwoModel } from "../models/registrationStepTwoModel";
import { registrationLastStepModel } from "../models/registrationLastStepModel";

export const useForm = (stage, submitCallback, amount, setPageScroll) => {
  let initModel = {};
  let initProgress = 0;

  switch (stage) {
    case 1:
      initModel = registrationFirstStepModel;
      initProgress = 0;
      break;
    case 2:
      initModel = registrationStepTwoModel;
      initProgress = registrationFirstStepModel.length * 5;
      break;
    case 3:
      initModel = registrationLastStepModel;
      initProgress =
        (registrationFirstStepModel.length + registrationStepTwoModel.length) *
        5;
      break;
    default:
      initModel = registrationFirstStepModel;
      initProgress = 0;
  }

  const [inputs, setInputs] = useState(initModel);
  const [progress, setProgress] = useState(initProgress);
  const [message, setMessage] = useState({
    type: null,
    text: null,
  });

  useEffect(() => {
    if (stage === 1) {
      // NOTE: The initModel[0].value is 'amount' field
      initModel[0].value = amount.toString();

      typeof window !== "undefined" && localStorage.setItem("amount", amount);
    }
  }, []);

  useEffect(() => {
    setInputs(initModel);
  }, [initModel]);

  useEffect(() => {
    setProgress(initProgress + calculateProgress());
  }, [inputs]);

  // TODO: reset all models
  const resetForm = () => {
    inputs.forEach(i => {
      i.value = "";
      i.alert = null;
    });
    setInputs([...inputs]);
  };

  const handleChange = e => {
    e.persist();

    inputs.forEach(i => {
      if (i.name === e.target.name) {
        i.value = i.type === "checkbox" ? e.target.checked : e.target.value;
        parseInput(i);
        validateInput(i);
      }
    });

    setInputs([...inputs]);

    inputs.forEach(i => validateInputInfo(i));
    if (inputs.some(i => i.hiddenAlert)) {
      setMessage({
        type: "error",
        text: FORM.FORM_INCORRECT,
      });
    } else {
      setMessage({
        type: "success",
        text: FORM.FORM_SUCCESS,
      });
    }

    setProgress(initProgress + calculateProgress());
  };

  const modifyInputs = e => {
    inputs.forEach(i => {
      if (i.name === e.target.name && i.onBlurParserFun) {
        if (i.name === "zip") {
          i.onBlurParserFun(i, inputs, setInputs, validateInput);
        } else {
          i.value = i.onBlurParserFun(e.target.value);

          setInputs([...inputs]);
        }
      }
    });
  };

  const handleSubmit = e => {
    e && e.preventDefault();

    setPageScroll(null);

    inputs.forEach(i => validateInput(i));
    if (inputs.some(i => i.alert)) {
      setInputs([...inputs]);

      const firstError = inputs.find(i => i.alert);
      setPageScroll("#" + firstError.name + "-form-group");

      setMessage({
        type: "error",
        text: FORM.FORM_INCORRECT,
      });
    } else {
      setMessage({
        type: "success",
        text: FORM.FORM_SUCCESS,
      });
      submitCallback();
    }
  };

  const parseInput = input =>
    (input.value = input.parseFun ? input.parseFun(input.value) : input.value);

  const validateInput = input => {
    let alert = null;
    input.validators &&
      input.validators.forEach(
        v =>
          (alert = v.isValidFun && !v.isValidFun(input.value) ? v.alert : alert)
      );
    input.alert = alert;
  };

  const validateInputInfo = input => {
    let alert = null;
    input.validators &&
      input.validators.forEach(
        v =>
          (alert = v.isValidFun && !v.isValidFun(input.value) ? v.alert : alert)
      );
    input.hiddenAlert = alert;
  };

  const calculateProgress = () => {
    let currentProgress = inputs.length * 5;

    inputs.forEach(input => {
      let isValid = true;

      input.validators &&
        input.validators.forEach(validator => {
          const isValidFun = validator.isValidFun;
          const value = input.value;

          if (isValidFun && !isValidFun(value)) {
            isValid = false;
          }
        });

      if (!isValid) {
        currentProgress -= 5;
      }
    });

    return currentProgress;
  };

  return [
    inputs,
    handleChange,
    modifyInputs,
    handleSubmit,
    resetForm,
    progress,
    message,
    setMessage,
  ];
};
