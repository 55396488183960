export const minMaxLimit = (expression, min, max, step) => {
  const regex = /[^0-9]/g;

  let value = expression.replace(regex, "");

  if (value > max) {
    value = 70000;
  } else if (value < min) {
    value = 1000;
  } else {
    value = Math.round(value / step) * step;
  }

  return "$ " + value + " MXN";
};

export const getPostalCode = (i, inputs, setInputs, validateInput) => {
  const url = "/consultas/getPostalCode.php?codigo=";
  const postalCode = i.value;

  fetch(url + postalCode, { method: "GET" })
    .then(response => response.json())
    .then(data => {
      let d_ciudad = ""; // city
      let d_estado = ""; // state
      let d_mnpio = []; // municipality
      let d_asenta = []; // colony

      function replaceMulti(haystack, needle, replacement) {
        haystack = haystack.replace("é", "e");
        haystack = haystack.replace("á", "a");
        haystack = haystack.replace("í", "i");
        haystack = haystack.replace("ó", "o");
        haystack = haystack.replace("ú", "u");
        haystack = haystack.toLowerCase();

        return haystack.split(needle).join(replacement);
      }

      function updateInputs() {
        inputs.forEach(i => {
          if (i.name === "city") {
            if (d_ciudad.length) {
              i.value = d_ciudad;
              i.readonly = true;

              validateInput(i);
            } else {
              i.readonly = false;
            }
          }

          if (i.name === "state") {
            if (d_estado.length) {
              i.options.forEach(o => {
                if (o.value === d_estado) {
                  o.selected = true;

                  i.value = o.value;
                } else {
                  o.disabled = true;
                }
              });

              validateInput(i);
            } else {
              i.options.forEach(o => {
                o.selected = false;
                o.disabled = false;
              });
            }
          }

          if (i.name === "municipality") {
            if (d_mnpio.length) {
              const uniqueMunicipality = d_mnpio.filter(
                (val, index, arr) => arr.indexOf(val) === index
              );

              if (uniqueMunicipality.length > 1) {
                i.type = "select";
                i.options = [{ value: "", name: "Elige Municipio" }];

                uniqueMunicipality.forEach(item => {
                  i.options.push({
                    value: replaceMulti(item, " ", "_"),
                    name: item,
                  });
                });
              } else {
                i.type = "text";
                i.value = uniqueMunicipality[0];
                i.readonly = true;

                delete i.options;

                validateInput(i);
              }
            } else {
              i.type = "text";
              i.readonly = false;

              if (i.options) {
                i.options.forEach(option => {
                  if (i.value === option.value) {
                    i.value =
                      option.name === "Elige Municipio" ? "" : option.name;
                  }
                });
              }
              delete i.options;
            }
          }

          if (i.name === "colony") {
            if (d_asenta.length) {
              const uniqueColony = d_asenta.filter(
                (val, index, arr) => arr.indexOf(val) === index
              );

              if (uniqueColony.length > 1) {
                i.type = "select";
                i.options = [{ value: "", name: "Elige colonia" }];

                uniqueColony.forEach(item => {
                  i.options.push({
                    value: replaceMulti(item, " ", "_"),
                    name: item,
                  });
                });
              } else {
                i.type = "text";
                i.value = uniqueColony[0];
                i.readonly = true;

                delete i.options;

                validateInput(i);
              }
            } else {
              i.type = "text";
              i.readonly = false;

              if (i.options) {
                i.options.forEach(option => {
                  if (i.value === option.value) {
                    i.value =
                      option.name === "Elige colonia" ? "" : option.name;
                  }
                });
              }
              delete i.options;
            }
          }
        });
      }

      data.forEach(function (obj, i) {
        const entries = Object.entries(obj);

        for (const [key, val] of entries) {
          if (key === "d_ciudad") {
            d_ciudad = val;
          }

          if (key === "d_estado") {
            d_estado = val;
            d_estado = replaceMulti(d_estado, " ", "_");
          }

          if (key === "D_mnpio") {
            d_mnpio.push(val);
          }

          if (key === "d_asenta") {
            d_asenta.push(val);
          }
        }
      });

      updateInputs();

      setInputs([...inputs]);
    })
    .catch(error => console.error("Postal code request error:", error));
};
