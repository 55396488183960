import React from "react";

export const ValidationAlert = ({ content }) => {
  return (
    <>
      {content && (
        <span className="error" dangerouslySetInnerHTML={{ __html: content }} />
      )}
    </>
  );
};
