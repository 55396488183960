import {
  checkAge,
  checkIsFilled,
  checkMinLength,
} from "../helpers/inputValidator";

export const registrationStepTwoModel = [
  {
    name: "first_name",
    label: "Nombre",
    type: "text",
    col: 12,
    validators: [
      {
        id: "first_name-required",
        isValidFun: expression => checkIsFilled(expression),
        alert: "Falta el nombre",
      },
    ],
  },
  {
    name: "middle_name",
    label: "Apellido paterno",
    type: "text",
    col: 12,
    validators: [
      {
        id: "middle_name-required",
        isValidFun: expression => checkIsFilled(expression),
        alert: "Falta el apellido paterno",
      },
    ],
  },
  {
    name: "last_name",
    label: "Apellido materno",
    type: "text",
    col: 12,
    validators: [
      {
        id: "last_name-required",
        isValidFun: expression => checkIsFilled(expression),
        alert: "Falta el apellido materno",
      },
    ],
  },
  {
    name: "date_birth",
    label: "Fecha de nacimiento",
    type: "date",
    placeholder: "mm / dd / yyyy",
    col: 12,
    validators: [
      {
        id: "date_birth-min-age",
        isValidFun: expression => checkAge(expression),
        alert: "Fecha no válida, recuerda que no puedes ser menor de edad",
      },
      {
        id: "date_birth-required",
        isValidFun: expression => checkIsFilled(expression),
        alert: "Campo obligatorio",
      },
    ],
  },
  {
    name: "gender",
    label: "Género",
    type: "radio",
    col: 12,
    options: [
      { value: "male", name: "Hombre" },
      { value: "female", name: "Mujer" },
    ],
    validators: [
      {
        id: "gender-required",
        isValidFun: expression => checkIsFilled(expression),
        alert: "Tienes que añadir tu género",
      },
    ],
  },
  {
    name: "id_code",
    label: "CURP",
    type: "text",
    col: 12,
    validators: [
      {
        id: "id_code-minlength",
        isValidFun: expression => checkMinLength(expression, 18),
        alert: "CURP no válido",
      },
      {
        id: "id_code-required",
        isValidFun: expression => checkIsFilled(expression),
        alert: "Falta añadir la CURP",
      },
    ],
  },
  {
    name: "work_status",
    label: "Ocupación",
    type: "select",
    col: 12,
    options: [
      { value: "", name: "Seleccione uno:" },
      { value: "employed", name: "Empleado" },
      { value: "self_employed", name: "Autónomo" },
      { value: "pensioner", name: "Pensionado" },
      { value: "student", name: "Estudiante" },
      { value: "unemployed", name: "Desempleado" },
      { value: "freelancer", name: "Independiente" },
      { value: "informal_employment", name: "Empleo informal o Proyectos" },
      { value: "public_employment", name: "Empleado publico" },
    ],
    validators: [
      {
        id: "work_status-required",
        isValidFun: checkIsFilled,
        alert: "Falta añadir tu Ocupación",
      },
    ],
  },
  {
    name: "bank_account",
    label: "¿Tienes cuenta Bancaria?",
    type: "radio",
    col: 12,
    options: [
      { value: "1", name: "Si" },
      { value: "0", name: "No" },
    ],
    validators: [
      {
        id: "bank_account",
        isValidFun: expression => checkIsFilled(expression),
        alert: "Campo obligatorio",
      },
    ],
  },
];
