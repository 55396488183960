import { checkIsFilled, checkIsNumber } from "../helpers/inputValidator";

import { getPostalCode } from "../helpers/inputOnBlurParser";

export const registrationLastStepModel = [
  {
    name: "zip",
    label: "Código postal",
    type: "text",
    inputMode: "decimal",
    onBlurParserFun: (i, inputs, setInputs, validateInput) =>
      getPostalCode(i, inputs, setInputs, validateInput),
    col: 12,
    validators: [
      {
        id: "zip-isnumber",
        isValidFun: checkIsNumber,
        alert: "Falta el código postal",
      },
      {
        id: "zip-required",
        isValidFun: expression => checkIsFilled(expression),
        alert: "Falta el código postal",
      },
    ],
  },
  {
    name: "street",
    label: "Calle",
    type: "text",
    col: 12,
    validators: [
      {
        id: "street-required",
        isValidFun: expression => checkIsFilled(expression),
        alert: "Falta la calle",
      },
    ],
  },
  {
    name: "house_number",
    label: "Número exterior",
    type: "text",
    inputMode: "decimal",
    col: 12,
    validators: [
      {
        id: "house_number-isnumber",
        isValidFun: checkIsNumber,
        alert: "Falta el número de la puerta",
      },
      {
        id: "house_number-required",
        isValidFun: checkIsFilled,
        alert: "Campo obligatorio",
      },
    ],
  },
  {
    name: "colony",
    label: "Colonia",
    type: "text",
    col: 12,
    validators: [
      {
        id: "colony-required",
        isValidFun: checkIsFilled,
        alert: "Falta la colonia",
      },
    ],
  },
  {
    name: "municipality",
    label: "Municipio",
    type: "text",
    col: 12,
    validators: [
      {
        id: "municipality-required",
        isValidFun: checkIsFilled,
        alert: "Falta el municipio",
      },
    ],
  },
  {
    name: "city",
    label: "Ciudad",
    type: "text",
    col: 12,
    validators: [
      {
        id: "city-required",
        isValidFun: checkIsFilled,
        alert: "Falta el estado",
      },
    ],
  },
  {
    name: "state",
    label: "Estado",
    type: "select",
    col: 12,
    options: [
      { value: "", name: "Seleccione uno:" },
      { value: "aguascalientes", name: "Aguascalientes" },
      { value: "baja_california", name: "Baja California" },
      { value: "baja_california_sur", name: "Baja California Sur" },
      { value: "campeche", name: "Campeche" },
      { value: "chiapas", name: "Chiapas" },
      { value: "chihuahua", name: "Chihuahua" },
      { value: "ciudad_de_mexico", name: "Ciudad de México" },
      { value: "coahuila_de_zaragoza", name: "Coahuila de Zaragoza" },
      { value: "colima", name: "Colima" },
      { value: "durango", name: "Durango" },
      { value: "mexico", name: "Estado de México" },
      { value: "guanajuato", name: "Guanajuato" },
      { value: "guerrero", name: "Guerrero" },
      { value: "hidalgo", name: "Hidalgo" },
      { value: "jalisco", name: "Jalisco" },
      { value: "mexico", name: "Mexico" },
      { value: "michoacan_de_ocampo", name: "Michoacán de Ocampo" },
      { value: "morelos", name: "Morelos" },
      { value: "nayarit", name: "Nayarit" },
      { value: "nuevo_leon", name: "Nuevo León" },
      { value: "oaxaca", name: "Oaxaca" },
      { value: "puebla", name: "Puebla" },
      { value: "queretaro", name: "Querétaro" },
      { value: "quintana_roo", name: "Quintana Roo" },
      { value: "san_luis_potosi", name: "San Luis Potosi" },
      { value: "sinaloa", name: "Sinaloa" },
      { value: "sonora", name: "Sonora" },
      { value: "tabasco", name: "Tabasco" },
      { value: "tamaulipas", name: "Tamaulipas" },
      { value: "tlaxcala", name: "Tlaxcala" },
      {
        value: "veracruz_de_ignacio_de_la_llave",
        name: "Veracruz de Ignacio de la Llave",
      },
      { value: "yucatan", name: "Yucatán" },
      { value: "zacatecas", name: "Zacatecas" },
    ],
    validators: [
      {
        id: "state-required",
        isValidFun: checkIsFilled,
        alert: "Falta el estado",
      },
    ],
  },
];
