import {
  checkEmailPattern,
  checkIsFilled,
  checkIsFilledIncome,
  checkIsFilledPhoneMobile,
  checkIsTrue,
} from "../helpers/inputValidator";

import { minMaxLimit } from "../helpers/inputOnBlurParser";

export const registrationFirstStepModel = [
  {
    name: "amount",
    label: "¿Cuál es el monto que necesitas?",
    type: "text",
    inputMode: "decimal",
    onBlurParserFun: expression => minMaxLimit(expression, 1000, 70000, 100),
    col: 12,
    masked: {
      thousandSeparator: ".",
      decimalSeparator: ",",
      allowEmptyFormatting: true,
      prefix: "$ ",
      suffix: " MXN",
    },
  },
  {
    name: "income",
    label: "Indica tus ingresos netos mensuales",
    type: "text",
    value: "0",
    inputMode: "decimal",
    col: 12,
    masked: {
      thousandSeparator: ".",
      decimalSeparator: ",",
      allowEmptyFormatting: true,
      prefix: "$ ",
      suffix: " MXN",
    },
    validators: [
      {
        id: "income-required",
        isValidFun: expression => checkIsFilledIncome(expression),
        alert: "Faltan tus ingresos",
      },
    ],
  },
  {
    name: "email",
    label: "Correo electrónico",
    type: "email",
    maxLength: 120,
    col: 12,
    validators: [
      {
        id: "email-pattern",
        isValidFun: checkEmailPattern,
        alert: "Falta el email o es incorrecto",
      },
      {
        id: "email-required",
        isValidFun: checkIsFilled,
        alert: "Falta el email o es incorrecto",
      },
    ],
  },
  {
    name: "phone_mobile",
    label: "Teléfono celular",
    type: "tel",
    placeholder: "+52 ",
    inputMode: "tel",
    col: 12,
    masked: {
      format: "+52 (###) ###-####",
      mask: "_",
    },
    validators: [
      {
        id: "phone-required",
        isValidFun: checkIsFilledPhoneMobile,
        alert: "Teléfono móvil incorrecto o falta",
      },
    ],
  },
  {
    name: "checkbox-privacy",
    label:
      'Acepto la <a target="_blank" href="/politica-privacidad">Política&nbsp;de&nbsp;privacidad</a> y las <a target="_blank" href="/condiciones-de-uso">Condiciones&nbsp;generales</a>',
    type: "checkbox",
    value: false,
    col: 12,
    validators: [
      {
        id: "policy-required",
        isValidFun: checkIsTrue,
        alert: "Tienes que aceptar la política de privacidad",
      },
    ],
  },
];
