import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { navigate, graphql } from "gatsby";
import scrollTo from "gatsby-plugin-smoothscroll";
import styled from "styled-components";
import "isomorphic-fetch";

import { API, FORM } from "../settings";
import { renderInput } from "../helpers/renderInput";
import { useForm } from "../hooks/use-form";

import { HelmetBlock } from "../components/HelmetBlock";
import { Header } from "../components/HeaderBlock";

import imgShield from "../images/shield.jpg";

import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/index.css";

const Registration = ({ data }) => {
  const [stage, setStage] = useState(1);
  const [fields, setFields] = useState([]);
  const [pageScroll, setPageScroll] = useState(null);
  const isDebugMode =
    typeof window !== `undefined` &&
    window.localStorage.getItem("debugMode") === "on";

  const formData = useSelector(state => state.form);

  const sendForm = () => {
    typeof window !== "undefined" &&
      window.gtag &&
      window.gtag("event", "Submit1success");

    if (stage < 3) {
      setStage(stage + 1);

      setFields(fields.concat(inputs));
    } else {
      const allFields = fields.concat(inputs);

      // TODO: move to helpers
      const checkFieldValue = (fieldsArray, fieldName, fieldValue) => {
        let result = false;
        fieldsArray.forEach(field => {
          if (field.name === fieldName) {
            result = field.value === fieldValue;
          }
        });

        return result;
      };

      const getFieldValue = (fieldsArray, fieldName) => {
        let result = "";
        fieldsArray.forEach(field => {
          if (field.name === fieldName) {
            result = field.value;
          }
        });

        return result;
      };

      fetch(API.SEND_CALC, {
        ...API.HEADERS.POST,
        body: JSON.stringify(allFields),
      })
        .then(response => response.json())
        .then(data => {
          //region Debug
          if (isDebugMode) {
            console.debug("All form fields:", allFields);
            console.debug("API response:", data);
          }
          //endregion

          let redirectToUrl = "http://f5447.site/vexi.mx/affilwin/1";
          const requestStatus = data.status;

          if (checkFieldValue(allFields, "bank_account", "1")) {
            if (data.status === "OK") {
              redirectToUrl = data.redirect_url;
            } else {
              redirectToUrl = "/comparador";
            }
          }

          //region Debug
          if (isDebugMode) {
            console.debug(
              "Bank account:",
              checkFieldValue(allFields, "bank_account", "1")
            );
            console.debug("Redirect to:", redirectToUrl);
          }
          //endregion

          window.gtag &&
            window.gtag("config", "UA-142148414-1", {
              custom_map: { dimension1: "id_user" },
            });
          window.gtag &&
            window.gtag("event", "id_user_dimension", {
              id_user: data.lead_id,
            });

          navigate("/success", {
            state: {
              requestStatus: requestStatus,
              redirectTo: redirectToUrl,
              name: getFieldValue(allFields, "first_name"),
              lead: data.lead_id,
            },
          });

          //commented from old version
          /*
          if (data && data.status) {
            setMessage({
              type: 'success',
              text: FORM.FORM_SUCCESS,
            });
            resetForm();
          } else {
            setMessage({
              type: "error",
              text: FORM.FORM_ERROR,
            });
          }
          */
        });
    }
  };

  const [
    inputs,
    setInputs,
    modifyInputs,
    setSubmit,
    resetForm,
    progress,
    message,
    setMessage,
  ] = useForm(stage, sendForm, formData.amount, setPageScroll);

  useEffect(() => {
    if (pageScroll) {
      scrollTo(pageScroll);
    }
  }, [pageScroll]);

  useEffect(() => {
    setPageScroll(null);

    if (stage !== 1) {
      scrollTo("#top");
    }
  }, [stage]);

  return (
    <>
      <HelmetBlock data={data.wpPage} />
      <Header data={data.wpPage} progress={progress} />
      <StyledFormBlock message={message}>
        <Container>
          <div className="form">
            <form>
              <div className="form__stage">
                <b>{stage}</b> / 3
              </div>
              <div className="form-row">
                {inputs.map(input =>
                  renderInput(input, setInputs, modifyInputs)
                )}
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                dangerouslySetInnerHTML={{ __html: "SIGUIENTE" }}
                onClick={setSubmit}
              />
            </form>
          </div>
        </Container>
      </StyledFormBlock>

      <StyledBadgeBlock className="reg-badge">
        <div className="container">
          <div className="reg-badge__content">
            Tu información <a href="#">está protegida</a>
          </div>
        </div>
      </StyledBadgeBlock>

      <StyledFooterBlock className="reg-footer">
        <div className="container">
          <div className="reg-footer__content">
            <p className="reg-footer__heading">Información importante</p>
            <p className="reg-footer__description">
              ¡Nunca te pediremos dinero por adelantado como requisito para la
              solicitud de un crédito!
            </p>
          </div>
        </div>
      </StyledFooterBlock>
    </>
  );
};

export default Registration;

const StyledFormBlock = styled.div`
  .container {
    @media (min-width: 1200px) {
      max-width: 960px;
    }
  }

  h1 {
    font-size: 16px;
    font-weight: 700;
    color: #378aff;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 28px;
    }
  }

  form {
    max-width: 392px;
    margin: 0 auto 0;
    .form__stage {
      margin: 10px 0 30px;
      color: #378aff;
      font-size: 28px;
      text-align: center;
      @media (min-width: 768px) {
        margin: 10px 0 50px;
      }
    }
    .form-row {
      @media (min-width: 768px) {
        margin: 0 -25px;
      }
    }
    .form-group {
      margin: 0 0 30px;
      @media (min-width: 768px) {
        padding: 0 25px;
        margin: 0 0 50px;
      }
    }

    .form-control + .error {
      font-size: 12px;
      color: red;

      @media (min-width: 768px) {
        font-size: 14px;
        position: absolute;
      }
    }

    input {
      height: auto;
      padding: 9px 20px;
      border: 1px solid #378aff;
      color: #4c4c4c;
      font-size: 16px;
      line-height: 24px;
      @media (min-width: 992px) {
        font-size: 18px;
      }
      &:focus {
        color: #4c4c4c;
        border-color: #378aff;
        box-shadow: none;
        outline: none;
        text-shadow: none;
      }
      &:invalid {
        box-shadow: none;
      }
      &.error {
        background-color: #ff8c8e;
        border: solid 1px #ff0505;
      }
    }
    input[type="date"] {
      color: #378aff;
    }
    input[readonly] {
      background: #f2f6fc;
      border-color: transparent;
    }

    .form-group_readonly {
      label {
        @media (min-width: 992px) {
          margin-bottom: 0;
        }
      }

      input {
        color: #378aff;
        border: none;
        padding: 10px 20px;
        background: #f2f6fc;

        @media (min-width: 992px) {
          width: 180px;
        }
      }
    }

    .form-control-income-wrapper {
      position: relative;

      &::before {
        content: "/ mensuales";
        position: absolute;
        right: 20px;
        color: #378aff;
        font-size: 16px;
        line-height: 44px;

        @media (min-width: 992px) {
          font-size: 18px;
        }
      }

      input {
        padding-right: 130px;
      }

      + .error {
        font-size: 12px;
        color: red;

        @media (min-width: 768px) {
          font-size: 14px;
          position: absolute;
        }
      }
    }

    .custom-checkbox {
      .custom-control-label {
        margin: 0;
        color: #112a4f;
        font-size: 14px;
        font-weight: 400;
        &::before {
          width: 16px;
          height: 16px;
          border-radius: 0;
          border: 1px solid #378aff;
          top: 2px;
        }
        &::after {
          top: 2px;
        }
        a {
          color: #378aff;
          font-weight: 700;
        }
      }

      .custom-control-input {
        &:focus ~ .custom-control-label::before {
          box-shadow: none;
        }
        &:checked ~ .custom-control-label::before {
          background: transparent;
          border-color: #378aff;
        }
        &:checked ~ .custom-control-label::after {
          top: 0;
          left: -23px;
          width: 20px;
          height: 20px;
          background: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 16.2'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M16.3 4e-8l-8.92 8.92-3.69-3.69-3.65 3.65 7.33 7.33 12.7-12.7z'/%3E%3Cpath fill='%23378aff' fill-rule='evenodd' d='M7.234 11.3088L3.4792 7.554 2.2276 8.8056 7.234 13.812 17.962 3.084l-1.2516-1.2516z'/%3E%3C/svg%3E%0A");
        }
        &.error ~ .custom-control-label::before {
          background-color: #ff8c8e;
          border: solid 1px #ff0505;
        }
      }

      + .error {
        position: absolute;
        padding: 0 0 0 1.5rem;
        color: red;
        font-size: 12px;
        @media (min-width: 768px) {
          font-size: 14px;
        }
      }
    }

    .btn-primary {
      display: block;
      width: 100%;
      min-width: 216px;
      background: #ff9d00;
      padding: 14px 28px;
      border: none;
      border-radius: 15px;
      margin: 0 auto;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 2px;
      text-transform: uppercase;
      @media (min-width: 992px) {
        width: auto;
      }
    }

    .btn-primary:hover,
    .btn-primary:focus {
      background: #e68d00;
    }

    .btn-primary:not(:disabled):not(.disabled):active {
      background: #ff9d00;
    }

    .btn-primary:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }
`;

const StyledBadgeBlock = styled.div`
  .reg-badge__content {
    max-width: 336px;
    background: url(${imgShield}) no-repeat center left 22px/24px 28px, #ebf3ff;
    padding: 13px 28px 13px 56px;
    border-radius: 26px;
    margin: 40px auto 30px;
    color: #378aff;
    @media (min-width: 768px) {
      margin: 50px auto;
    }
    a {
      color: inherit;
      font-weight: 700;
    }
  }
`;

const StyledFooterBlock = styled.div`
  background: #f9f9f9;
  padding: 30px 0;
  font-size: 16px;
  text-align: center;
  @media (min-width: 768px) {
    padding: 50px 0;
    font-size: 18px;
  }
  .reg-footer__content {
    max-width: 610px;
    margin: 0 auto;
    > * {
      margin: 0;
    }
    > * + * {
      margin: 10px 0 0;
    }
    .reg-footer__heading {
      color: #ff9d00;
      font-weight: 700;
    }
  }
`;

export const pageQuery = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      databaseId
      parentDatabaseId
      seo {
        title
        metaDesc
      }
    }
  }
`;
