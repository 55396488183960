export const checkIsNumber = number => {
  const regex = /^[0-9]+$/;

  return regex.test(number) || !number;
};

export const checkEmailPattern = mail => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regex.test(mail);
};

export const checkAtLeastLength = (expression, length) =>
  expression && expression.trim().length >= length;

export const checkMinLength = (expression, length) =>
  expression && expression.trim().length >= length;

export const checkMaxLength = (expression, length) =>
  expression && expression.trim().length <= length;

export const checkMinValue = (expression, value) =>
  expression && Number(expression.replace(/[^0-9]/g, "")) >= value;

export const checkIsFilled = expression => expression && expression.length > 0;

export const checkIsTrue = expression => expression;

// Specific fields validation
export const checkIsFilledIncome = expression => {
  return (
    expression &&
    Number(expression.replace(/[^0-9]/g, "")) !== 0 &&
    expression.replace(/[^0-9]/g, "").length
  );
};

export const checkIsFilledPhoneMobile = expression =>
  expression && expression.replace(/[^0-9]/g, "").length === 12;

// NOTE: логика перенесена со старой версии goprestamo.mx и адаптирована, принцип не менялся
export const checkAge = expression => {
  const now = new Date();
  const birthday = new Date(expression);

  const str = String(expression);
  const re = /[dmy]/g;

  if (str.search(re) >= 0) {
    return false;
  } else {
    now.setHours(0, 0, 0, 0);

    let age = now.getFullYear() - birthday.getFullYear();
    const monthsDiff = now.getMonth() - birthday.getMonth();

    if (
      monthsDiff < 0 ||
      (monthsDiff === 0 && now.getDate() < birthday.getDate())
    ) {
      age--;
    }

    return now > birthday && age >= 18;
  }
};
